import React from "react"
import * as newsletterStyles from "./newsletterForm.module.scss"
import ContentContainer from "./contentContainer"
import CallToActionButton from "./callToActionButton"
import TwoColumnComponent from "./twoColumnComponent"

class NewsletterForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      firstname: "",
      lastname: "",
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    })
  }

  render() {
    let url
    if (this.props.isFoerdermitglied) {
      url = "https://t325160ec.emailsys1a.net/196/1383/3497043e4b/subscribe/form.html?_g=1664866500"
    } else {
      url = "https://t325160ec.emailsys1a.net/196/1064/988bc1b588/subscribe/form.html?_g=1664866336"
    }
    const form =
      <form method="post" action={url}>
        <div className={`columns ${newsletterStyles.inputTop}`}>
          <div className={`column is-12`}>
            <input className={newsletterStyles.input} type="text" name="email" id="email" placeholder="E-Mail"
                   value={this.state.email} onChange={this.handleChange} />
          </div>
        </div>
        <div className="columns">
          <div className={`column is-6 ${newsletterStyles.inputLeft}`}>
            <input className={`${newsletterStyles.input}`} type="text" name="firstname" id="firstname"
                   placeholder="Vorname (optional)"
                   value={this.state.firstname} onChange={this.handleChange} />
          </div>
          <div className={`column is-6 ${newsletterStyles.inputRight}`}>
            <input className={`${newsletterStyles.input}`} type="text" name="lastname" id="lastname"
                   placeholder="Nachname (optional)"
                   value={this.state.lastname} onChange={this.handleChange} />
          </div>
        </div>
        <div className="columns">
          <div className="column is-12">
            <p>Für den Versand unserer Newsletter nutzen wir <a href="https://www.rapidmail.de/"
                                                                target="_blank">rapidmail</a>. Mit Deiner Anmeldung
              stimmst Du zu, dass die eingegebenen Daten an rapidmail übermittelt werden. Beachte bitte deren <a
                href="https://www.rapidmail.de/agb" target="_blank">AGB</a> und <a
                href="https://www.rapidmail.de/datenschutz-kundenbereich" target="_blank">Datenschutzbestimmungen</a>.
            </p>
            <p>
              Mit der Anmeldung bist Du damit einverstanden, dass Deine personenbezogenen Daten für Werbezwecke
              verarbeitet werden und
              eine werbliche Ansprache per E-Mail erfolgt. Die erteilte Einwilligung kannst Du jederzeit mit Wirkung für
              die Zukunft in jeder angemessenen Form widerrufen.
            </p>
            <p>
              Du erhältst im Anschluss eine E-Mail, in der Du Deine Anmeldung mit Bestätigung der E-Mail-Adresse
              abschließen kannst.
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column is-12">
            <CallToActionButton submit={true} text="Anmelden" />
          </div>
        </div>
      </form>

    return (
      <div>
        <ContentContainer paddingMobile>
          <TwoColumnComponent title={this.props.title} titleContent={this.props.subtitle}
                              backbutton={false}>
            <div>{form}</div>
          </TwoColumnComponent>
        </ContentContainer>
      </div>
    )
  }
}

export default NewsletterForm
