import React from "react"
import * as buttonStyles from "./callToActionButton.module.scss"

function CallToActionButton(props) {
    const { submit, text } = props
    return(
        <button className={buttonStyles.button} type={`${submit ? "submit": ""}`}>
                <h3>{text}</h3>
        </button>
    )
}

export default CallToActionButton
